<template>
  <div class="col-6">
    <div class="d-flex flex-column mb-3">
      <div class="mb-3">
        <b-form-input
          v-model="query"
          :placeholder="searchPlaceholder"
          v-on:keyup.enter="performSearch"
        ></b-form-input>
      </div>
      <div class="d-flex">
        <div class="col-3 pl-0">
          <multiselect
            v-model="selectedLanguages"
            :options="languageCodesOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :searchable="false"
            placeholder="Languages"
            track-by="value"
            label="label"
          >
            <template
              slot="option"
              slot-scope="props"
              class="d-flex align-items-center bg-black"
              ><country-flag :country="country(props.option.label)" />
              <span class="option__title">{{ props.option.label }}</span>
            </template>
          </multiselect>
        </div>
        <div class="ml-2">
          <multiselect
            v-model="selectedSets"
            :options="setsOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            group-label="label"
            group-values="options"
            :group-select="true"
            placeholder="Policy sets"
            track-by="id"
            :limit="2"
            label="label"
          ></multiselect>
        </div>
        <div class="ml-auto">
          <a class="btn btn-primary" @click="performSearch">Search</a>
        </div>
      </div>
    </div>
    <div v-if="!results">Perform a search to see results</div>
    <div v-else>
      <h4>{{ results.results.length | formatNumber }} results</h4>
      <div v-if="results.hitsLimited" class="alert alert-info">
        There were more than {{ results.results.length | formatNumber }} results
        - refine your search to be more precise.
      </div>

      <div v-for="result in results.results" :key="result.hash">
        <div class="d-flex align-items-center">
          <router-link
            :to="{
              name: 'admx-set-view',
              params: {
                setId: result.setId,
                policyClass: result.policyClass,
                languageCode: result.languageCode,
                policyId: result.policyId,
              },
            }"
          >
            <h5 v-html="result.displayNameHighlighted"></h5>
          </router-link>
          <span class="ml-auto">
            {{ result.groupName }}
          </span>
          <country-flag :country="country(result.languageCode)" />
        </div>
        <p v-html="result.descriptionHighlighted"></p>
      </div>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from "vue-multiselect";
import CountryFlag from "vue-country-flag";
import * as UserSettings from "../user-settings";
import * as Utility from "../utilities.js";
import XXH from "xxhashjs";

export default {
  components: {
    Multiselect,
    CountryFlag,
  },
  created() {
    this.fetchIndex();

    this.updateSearchPlaceholder();
    window.setInterval(this.updateSearchPlaceholder, 5000);
  },
  watch: {
    selectedLanguages() {
      this.performSearch();
    },
    selectedSets() {
      this.performSearch();
    },
  },
  methods: {
    fetchIndex() {
      this.$root.$api.getSearchIndex().then((data) => {
        this.model = data;

        // Select all sets
        this.selectedSets = this.setsOptions.flatMap((x) => x.options);

        // Select language
        const userCc = UserSettings.getCountryCode() || "en-US";

        this.selectedLanguages = this.languageCodesOptions.filter(
          (x) => this.country(x.value) == userCc
        );
      });
    },
    performSearch() {
      const modelParams = {
        selectedLanguages: this.selectedLanguages.map((x) => x.value),
        selectedSets: this.selectedSets.map((x) => x.id),
        query: this.query,
      };

      // Reload the comparison
      if (!Utility.shallowEqual(this.modelParams, modelParams)) {
        this.modelParams = modelParams;

        if (modelParams.query === "") {
          // Clear search
          this.results = undefined;
        } else {
          this.$root.$api
            .getSearch(
              modelParams.selectedLanguages,
              modelParams.selectedSets,
              modelParams.query
            )
            .then((data) => {
              // Ensure we're getting a response for our request
              if (!Utility.shallowEqual(this.modelParams, modelParams)) return;

              for (const res of data.results) {
                const hasher = XXH.h32(0x0000);
                hasher.update(res.policyId);
                hasher.update(res.setId);
                hasher.update(res.policyClass);

                const hashed = hasher.digest();
                const hashCode = hashed.toString(16);

                res.hash = hashCode;
              }

              this.results = data;
            });
        }
      }
    },
    country(languageCode) {
      return Utility.getCountryCode(languageCode);
    },
    updateSearchPlaceholder() {
      const options = [
        "ecc",
        "deskt*",
        "desktop AND hide",
        "desktop hide",
        "remove computer",
        "(remove OR delete) AND task",
      ];

      const index = Math.floor(Math.random() * options.length);
      this.searchPlaceholder = options[index];
    },
  },
  computed: {
    languageCodesOptions() {
      return this.model.possibleLanguages.map((x) => {
        return {
          label: x,
          value: x,
        };
      });
    },
    setsOptions() {
      return this.model.possibleGroups.map((group) => {
        return {
          id: group.id,
          label: group.displayName,
          options: group.sets.map((set) => {
            return {
              id: set.id,
              label: set.displayName,
            };
          }),
        };
      });
    },
    languageCode() {
      return this.$route.params.languageCode;
    },
  },
  data() {
    return {
      isLoading: false,
      searchPlaceholder: "",
      selectedLanguages: [],
      selectedSets: [],
      query: "",
      model: {
        possibleLanguages: [],
        possibleGroups: [],
      },
      results: undefined,
      modelParams: {},
    };
  },
};
</script>
